.App {
  text-align: center;
}

.App-logo {
  width: 300px;
}

.App-header {
  background-color: lightgrey;
  padding: 10px;
  color: navy;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App-content {
  text-align: left;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
